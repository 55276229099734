@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Diphylleia&display=swap');

* {
    font-family: 'Inter', sans-serif;
    text-decoration: none;
}

body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

@keyframes blurIn {
    from {filter: blur(2px);}
    to {filter: blur(0px);}
}

@keyframes slideIn {
    from {margin-top: 230px;}
    to {margin-top: 250px;}
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*
    Desktop devices
 */
@media (min-width: 1025px) {

    /*
    Navbar
    */

    .NavbarContainer {
        height: 80px;
        display: flex;
        width: 85%;
        margin: auto;
        padding-bottom: 10px;

        z-index: 1010; /* This ensures the navbar stays on top of other content */
        top: 0;

        animation-fill-mode: forwards;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    .logo {
        align-items: center;
        display: flex;
        justify-content: left;
        margin-left: 10px;
        padding-top: 20px;
    }

    .logo-img {
        width: 48px;
        height: 48px;
    }

    .logo-github {
        width: 48px;
        height: 48px;
    }

    .logo-text {
        color: #000;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        padding-top: 10px;
    }

    .navigation {
        display: flex;
        margin-left: auto;
        align-items: center;
    }

    .navigation-element-text-active {
        color: #b07373;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        margin-right: 35px;
        padding-top: 10px;

        transition: color 0.3s;
    }

    .navigation-element-text {
        color: #646464;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        margin-right: 35px;
        padding-top: 10px;

        transition: color 0.3s;
    }

    .navigation-element-text:hover {
        color: #925f5f;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        margin-right: 35px;
        padding-top: 10px;
    }

    .navigation-element-text-active:hover {
        color: #925f5f;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        margin-right: 35px;
        padding-top: 10px;
    }

    .btn-newlist {
        border-radius: 5px;
        background: #000000;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 140px;
        height: 50px;
        margin-top: 10px;

        transition: background-color 0.3s;
    }

    .btn-newlist:hover {
        border-radius: 5px;
        background: #b07373;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 140px;
        height: 50px;
        margin-top: 10px;
    }

    .btn-newlist-text {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 12.5px;
        user-select: none;
    }

    .navigation-divider {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
    }

    .navigation-language {
        width: 24px;
        height: 24px;
    }

    .navigation-language-icon {
        margin-top: 2px;

        width: 50px;
        height: 35px;
    }

    /*
    Main page content
     */

    .main-background {
        background: url('Imgs/background.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh; /* Full viewport height */
        width: 100%;
        position: absolute;
        top: 80px; /* Push down by the height of the navbar */
        left: 0;
        z-index: -1; /* Set behind the content */

        margin-top: 10px;
    }



    .main-h1 {
        color: rgba(0, 0, 0, 0.75);
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 47%;
        margin-block-end: 0.2em;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    .main-content-informatie-ceremonie-container {
        display: grid;
        align-items: center;
        place-items: center;

        width: 70%;
        margin-left: 15%;

        margin-top: 75px;

        animation-fill-mode: forwards;
        animation-name: slideIn;
        animation-duration: 1.5s;
    }

    .main-content-informatie-overons-container {
        display: grid;
        align-items: center;
        place-items: center;

        width: 70%;
        margin-left: 15%;

        margin-top: 30px;
    }

    .main-content-informatie-hoewerkt-container {
        display: grid;
        align-items: center;
        place-items: center;

        width: 70%;
        margin-left: 15%;

        margin-top: 30px;
    }

    .main-content-informatie-voorwie-container {
        display: flex;
        align-items: center;
        place-items: center;

        width: 70%;

        margin-top: 30px;
    }

    .main-content-informatie-text {
        color: rgb(0, 0, 0);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-block-end: 0.2em;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    .main-content-informatie-h2 {
        color: rgba(0, 0, 0, 0.63);
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 47%;
        margin-block-end: 0.2em;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    .contact-h2 {
        color: rgba(0, 0, 0, 0.81);
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        font-family: 'Diphylleia', serif;
    }

    .contact-form-container {
        display: flex;
        align-items: center;
        place-items: center;

        width: 70%;

        margin-top: 100px;
    }

    .contact-form-container-info-left {
        display: grid;
        align-items: center;
        place-items: center;

        width: 70%;
        margin-right: 6%;

        margin-top: 100px;
    }

    .contact-form-container-info-right {
        display: grid;
        align-items: center;
        place-items: center;

        width: 70%;
        margin-left: 6%;

        margin-top: 100px;
    }

    .btn-contact {
        border-radius: 8px;
        background: #000;
        width: 108%;
        height: 50px;
        text-decoration: none;
    }

    .main-content-container {
        display: grid;
        align-items: center;
        place-items: center;

        margin-top: 75px;

        animation-fill-mode: forwards;
        animation-name: slideIn;
        animation-duration: 1.5s;
    }

    .main-content-rectangle {
        background-color: rgba(255, 255, 255, 0.71);
        border-radius: 24px;

        width: 60%;
        margin-left: 20%;
        padding-bottom: 30px;
    }

    .main-buttons-container {
        margin-top: 20px;
        display: flex;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1.5s;
    }

    .btn-main-content {
        border-radius: 16px;
        border: 2px solid #000000;
        background-color: transparent;
        width: 250px;
        height: 50px;
        margin-top: 2.5px;
        text-decoration: none;

        transition: border 0.4s;
        transition: background-color 0.4s;
    }

    .btn-main-content:hover {
        border-radius: 16px;
        border: 2px solid #b07373;
        background-color: rgba(0, 0, 0, 0.15);
        width: 250px;
        height: 50px;
        margin-top: 2.5px;
        text-decoration: none;
    }

    .btn-main-content-text {
        color: #000000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 12.5px;
        text-decoration: none;
        user-select: none;
    }

    .main-content-information {
        width: 100%;

        margin-top: 350px;
        padding-bottom: 50px;

        background: rgb(255, 255, 255);
    }

    .main-content-information-second {
        width: 100%;

        padding-bottom: 50px;
        background: rgb(248, 255, 252);
    }

    .main-content-information-third {
        width: 100%;
        margin-left: 15%;
        padding-bottom: 50px;
        background: rgb(255, 255, 255);
    }

    .main-content-information-fourth {
        width: 100%;
        padding-bottom: 50px;
        background: rgb(248, 255, 252);
    }

    .main-content-informatie-voorwie-container-text {
        display: grid;
        width: 75%;
        margin-right: 10%;

        justify-content: center;
        align-items: center;
        place-items: center;
    }

    .main-content-informatie-image {
        place-content: end;

        margin-top: 30px;
        margin-left: 10%;
    }

    .about-us-quote-text {
        color: rgba(0, 0, 0, 0.63);
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 50%;
        margin-left: 25%;
        margin-block-end: 0.2em;

        font-family: 'Diphylleia', serif;
    }

    .main-content-informatie-text-wie {
        color: rgb(0, 0, 0);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-block-end: 0.2em;
        width: 120%;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    .voorbereiding-informatie-text {
        color: rgb(0, 0, 0);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-block-end: 0.2em;
        width: 40%;
        margin-left: 30%;

        font-family: 'Diphylleia', serif;
    }

    .voorbereiding-informatie-text-hyperlink {
        color: rgb(146, 94, 94);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        font-family: 'Diphylleia', serif;
    }

    .main-content-informatie-h2-wie {
        color: rgba(0, 0, 0, 0.63);
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-block-end: 0.2em;
        width: 120%;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    /*
    Footer
     */

    .footer-container {
        width: 100%;
        display: flex;
        padding-bottom: 20px;

        background-color: #252525;

        justify-content: center;
    }

    .footer-text-container {
        display: flex;
        justify-content: center;
        width: 75%;
    }

    .logo-text-footer {
        color: #FFF;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        margin-right: 40%;
    }

    .footer-text {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        padding-top: 20px;
    }

    /*
    Agenda
     */

    .main-background-agenda {
        background: url('Imgs/background.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 200%; /* Full viewport height */
        width: 100%;
        z-index: -1; /* Set behind the content */

        padding-bottom: 50px;
        padding-top: 5px;
    }

    .agenda-container {
        width: 95%;
        margin-left: 2.5%; /* Center the container */
        margin-top: 2.5%;
        border-radius: 10px;
        min-height: 93vh; /* Use min-height to ensure that content can expand */
        display: flex; /* Using flex to center the Card */
        align-items: flex-start; /* Aligns the Card to the top with space for margin */
        justify-content: center; /* Centers the Card horizontally */
        background-color: rgba(255, 255, 255, 0.92);
        padding-top: 1rem; /* This adds a little top margin inside the container */
        padding-bottom: 2rem; /* This adds a little bottom margin inside the container */
    }

    .agenda-row {
        width: 80%; /* Set the width of the row to 80% of its parent */
        margin-top: 2rem; /* This adds a little top margin to the Card */
        /* Rest of your styles */
    }

    .agenda-card {
        margin-bottom: 20px;
    }


    /*
Error page
 */

    .error-container {
        width: 85%;
        margin: auto;
        display: grid;

        margin-top: 12.5%;
        margin-bottom: 20%;
    }

    .error-title {
        color: #000;
        text-align: center;
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .error-text {
        color: #000;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        width: 60%;
        margin: auto;
    }

    .navigation-drawer {
        display: none;
    }

    .navigation-drawer-button {
        display: none;
    }

}




/*
    Mobile devices
 */
@media (max-width: 1024px) {

    /*
    Navbar
    */

    .NavbarContainer {
        height: 80px;
        display: flex;
        width: 85%;
        margin: auto;
        padding-bottom: 10px;

        z-index: 1010; /* This ensures the navbar stays on top of other content */
        top: 0;

        animation-fill-mode: forwards;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    .btn-contact-us {
        display: none;
    }

    .logo {
        align-items: center;
        display: flex;
        justify-content: left;
        margin-left: 10px;
        padding-top: 20px;
    }

    .logo-img {
        width: 48px;
        height: 48px;
    }

    .logo-github {
        width: 48px;
        height: 48px;
    }

    .navigation-drawer-button {
        width: 48px;
        height: 48px;

        padding-top: 10px;
    }

    .navigation-drawer {
        padding-top: 10px;
    }

    .logo-text {
        color: #000;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        padding-top: 10px;
    }

    .navigation {
        display: flex;
        margin-left: auto;
        align-items: center;
    }

    .navigation-element-text-active {
        color: #b07373;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        padding-top: 10px;

        transition: color 0.3s;
    }

    .navigation-element-text {
        color: #ffffff;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        padding-top: 10px;

        transition: color 0.3s;
    }

    .navigation-element-text:hover {
        color: #925f5f;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        padding-top: 10px;
    }

    .navigation-element-text-active:hover {
        color: #925f5f;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        padding-top: 10px;
    }

    .btn-newlist {
        border-radius: 5px;
        background: #000000;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 140px;
        height: 50px;
        margin-top: 10px;

        transition: background-color 0.3s;
    }

    .btn-newlist:hover {
        border-radius: 5px;
        background: #b07373;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 140px;
        height: 50px;
        margin-top: 10px;
    }

    .btn-newlist-text {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 12.5px;
        user-select: none;
    }

    .navigation-divider {
        display: none;
    }

    .navigation-language {
        width: 24px;
        height: 24px;
    }

    .navigation-language-icon {
        margin-top: 2px;
        margin-right: 10px;

        width: 50px;
        height: 35px;
    }

    /*
    Main page content
     */

    .main-background {
        background: url('Imgs/background.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh; /* Full viewport height */
        width: 100%;
        position: absolute;
        top: 80px; /* Push down by the height of the navbar */
        left: 0;
        z-index: -1; /* Set behind the content */

        margin-top: 10px;
    }



    .main-h1 {
        color: rgba(0, 0, 0, 0.75);
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 90%;
        margin-block-end: 0.2em;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    .main-content-informatie-ceremonie-container {
        display: grid;
        align-items: center;
        place-items: center;

        width: 70%;
        margin-left: 15%;

        margin-top: 75px;

        animation-fill-mode: forwards;
        animation-name: slideIn;
        animation-duration: 1.5s;
    }

    .main-content-informatie-overons-container {
        display: grid;
        align-items: center;
        place-items: center;

        width: 70%;
        margin-left: 15%;

        margin-top: 30px;
    }

    .main-content-informatie-hoewerkt-container {
        display: grid;
        align-items: center;
        place-items: center;

        width: 95%;
        margin-left: 2.5%;

        margin-top: 30px;
    }

    .main-content-informatie-voorwie-container {
        display: flex;
        align-items: center;
        place-items: center;

        width: 70%;

        margin-top: 30px;
    }

    .main-content-informatie-text {
        color: rgb(0, 0, 0);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-block-end: 0.2em;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    .main-content-informatie-h2 {
        color: rgba(0, 0, 0, 0.63);
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 90%;
        margin-block-end: 0.2em;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    .contact-h2 {
        color: rgba(0, 0, 0, 0.81);
        text-align: center;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        font-family: 'Diphylleia', serif;
    }

    .contact-form-container {
        width: 70%;

        margin-top: 100px;
    }

    .contact-form-container-info-left {
        display: grid;
        align-items: center;
        place-items: center;

        width: 90%;

        margin-top: 100px;
    }

    .contact-form-container-info-right {
        display: grid;
        align-items: center;
        place-items: center;

        width: 70%;

        margin-top: 100px;
    }

    .btn-contact {
        border-radius: 8px;
        background: #000;
        width: 108%;
        height: 50px;
        text-decoration: none;
    }

    .main-content-container {
        display: grid;
        align-items: center;
        place-items: center;

        margin-top: 75px;

        animation-fill-mode: forwards;
        animation-name: slideIn;
        animation-duration: 1.5s;
    }

    .main-content-rectangle {
        background-color: rgba(255, 255, 255, 0.71);
        border-radius: 24px;

        width: 80%;
        margin-left: 10%;
        padding-bottom: 30px;
    }

    .main-buttons-container {
        margin-top: 20px;
        display: flex;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1.5s;
    }

    .btn-main-content {
        border-radius: 16px;
        border: 2px solid #000000;
        background-color: transparent;
        width: 250px;
        height: 50px;
        margin-top: 2.5px;
        text-decoration: none;

        transition: border 0.4s;
        transition: background-color 0.4s;
    }

    .btn-main-content:hover {
        border-radius: 16px;
        border: 2px solid #b07373;
        background-color: rgba(0, 0, 0, 0.15);
        width: 250px;
        height: 50px;
        margin-top: 2.5px;
        text-decoration: none;
    }

    .btn-main-content-text {
        color: #000000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 12.5px;
        text-decoration: none;
        user-select: none;
    }

    .main-content-information {
        width: 100%;

        margin-top: 350px;
        padding-bottom: 50px;

        background: rgb(255, 255, 255);
    }

    .main-content-information-second {
        width: 100%;

        padding-bottom: 50px;
        background: rgb(248, 255, 252);
    }

    .main-content-information-third {
        width: 80%;
        margin-left: 20%;
        padding-bottom: 50px;
        background: rgb(255, 255, 255);
    }

    .main-content-information-fourth {
        width: 100%;
        padding-bottom: 50px;
        background: rgb(248, 255, 252);
    }

    .main-content-informatie-voorwie-container-text {
        display: grid;
        width: 100%;

        justify-content: center;
        align-items: center;
        place-items: center;
    }

    .main-content-informatie-image {
        place-content: end;
        display: none;

        margin-top: 30px;
        margin-left: 10%;
    }

    .about-us-quote-text {
        color: rgba(0, 0, 0, 0.63);
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 80%;
        margin-left: 10%;
        margin-block-end: 0.2em;

        font-family: 'Diphylleia', serif;
    }

    .main-content-informatie-text-wie {
        color: rgb(0, 0, 0);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-block-end: 0.2em;
        width: 120%;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    .voorbereiding-informatie-text {
        color: rgb(0, 0, 0);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-block-end: 0.2em;
        width: 80%;
        margin-left: 10%;

        font-family: 'Diphylleia', serif;
    }

    .voorbereiding-informatie-text-hyperlink {
        color: rgb(146, 94, 94);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        font-family: 'Diphylleia', serif;
    }

    .main-content-informatie-h2-wie {
        color: rgba(0, 0, 0, 0.63);
        text-align: center;
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-block-end: 0.2em;
        width: 90%;

        font-family: 'Diphylleia', serif;

        filter: blur(10px);
        animation-fill-mode: forwards;
        animation-name: blurIn;
        animation-duration: 1s;
    }

    .contact-form-container {
        width: 70%;

        margin-top: 100px;
    }

    /*
    Footer
     */

    .footer-container {
        width: 100%;
        display: flex;
        padding-bottom: 20px;

        background-color: #252525;

        justify-content: center;
    }

    .footer-text-container {
        display: flex;
        justify-content: center;
        width: 95%;
    }

    .logo-text-footer {
        color: #FFF;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        margin-top: 7.5px;
        margin-right: 40%;
    }

    .footer-text {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        padding-top: 20px;
    }

    /*
    Agenda
     */

    .main-background-agenda {
        background: url('Imgs/background.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 200%; /* Full viewport height */
        width: 100%;
        z-index: -1; /* Set behind the content */

        padding-bottom: 50px;
        padding-top: 5px;
    }

    .agenda-container {
        width: 95%;
        margin-left: 2.5%; /* Center the container */
        margin-top: 2.5%;
        border-radius: 10px;
        min-height: 93vh; /* Use min-height to ensure that content can expand */
        display: flex; /* Using flex to center the Card */
        align-items: flex-start; /* Aligns the Card to the top with space for margin */
        justify-content: center; /* Centers the Card horizontally */
        background-color: rgba(255, 255, 255, 0.92);
        padding-top: 1rem; /* This adds a little top margin inside the container */
        padding-bottom: 2rem; /* This adds a little bottom margin inside the container */
    }

    .agenda-row {
        width: 70%; /* Set the width of the row to 80% of its parent */
        margin-top: 2rem; /* This adds a little top margin to the Card */
        margin-right: 7.5%;
        /* Rest of your styles */
    }

    .agenda-card {
        margin-bottom: 20px;
    }


    /*
Error page
 */

    .error-container {
        width: 85%;
        margin: auto;
        display: grid;

        margin-top: 12.5%;
        margin-bottom: 20%;
    }

    .error-title {
        color: #000;
        text-align: center;
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .error-text {
        color: #000;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        width: 60%;
        margin: auto;
    }

}